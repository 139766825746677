<template>
  <div class="distribution-div">
      <div class="container">
          <div class="tree-left">
           <div class="tree-title">
               床位管理人员
           </div>
           <Tree :data="data1"></Tree>
          </div>
          <div class="table-right">
              <Table stripe border @on-selection-change="tableSelete"  :columns="talbeColumns" :data="tableData"></Table>
          </div>
      </div>
  </div>
</template>

<script>
  export default {
    //import引入的组件需要注入到对象中才能使用
    components: {},
    data() {
      //这里存放数据
      return {
              data1: [
                    {
                        title: '选择成员',
                        expand: true,
                        children: [
                            {
                                title: '成员1',
                                expand: true,
                            },
                            {
                                title: '成员1',
                                expand: true,
                            },
                            {
                                title: '成员1',
                                expand: true,
                            },
                            {
                                title: '成员1',
                                expand: true,
                            },
                            {
                                title: '成员1',
                                expand: true,
                            },
                            {
                                title: '成员1',
                                expand: true,
                            },
                            {
                                title: '成员1',
                                expand: true,
                            },
                            {
                                title: '成员1',
                                expand: true,
                            },
                            {
                                title: '成员1',
                                expand: true,
                            },
                            {
                                title: '成员1',
                                expand: true,
                            },
                            {
                                title: '成员1',
                                expand: true,
                            },
                            {
                                title: '成员1',
                                expand: true,
                            },
                            {
                                title: '成员1',
                                expand: true,
                            },
                            {
                                title: '成员1',
                                expand: true,
                            },
                        ]
                    }
                ],
        talbeColumns:[
            {
                type:'selection',
                align:'center',
                width:80
            },
            {
                title: "名称",
                key: "name",
                minWidth: 200,
                align: "center"
            },
            {
                title: "性别",
                key: "sex",
                minWidth: 200,
                align: "center"
            },
            {
                title: "年龄",
                key: "age",
                minWidth: 200,
                align: "center"
            },
            {
                title: "地址",
                key: "orgCode",
                minWidth: 200,
                align: "center"
            },
        ],
        tableData:[
            {name:'牛',sex:'男',age:81,orgCode:'番禺区小谷围街道星汇社区键盘花园WN栋7117房'},
            {name:'牛',sex:'男',age:81,orgCode:'番禺区小谷围街道星汇社区键盘花园WN栋7117房'},
            {name:'牛',sex:'男',age:81,orgCode:'番禺区小谷围街道星汇社区键盘花园WN栋7117房'},
            {name:'牛',sex:'男',age:81,orgCode:'番禺区小谷围街道星汇社区键盘花园WN栋7117房'},
            {name:'牛',sex:'男',age:81,orgCode:'番禺区小谷围街道星汇社区键盘花园WN栋7117房'},
            {name:'牛',sex:'男',age:81,orgCode:'番禺区小谷围街道星汇社区键盘花园WN栋7117房'},
            {name:'牛',sex:'男',age:81,orgCode:'番禺区小谷围街道星汇社区键盘花园WN栋7117房'},
            {name:'牛',sex:'男',age:81,orgCode:'番禺区小谷围街道星汇社区键盘花园WN栋7117房'},
            {name:'牛',sex:'男',age:81,orgCode:'番禺区小谷围街道星汇社区键盘花园WN栋7117房'},
            {name:'牛',sex:'男',age:81,orgCode:'番禺区小谷围街道星汇社区键盘花园WN栋7117房'},
            {name:'牛',sex:'男',age:81,orgCode:'番禺区小谷围街道星汇社区键盘花园WN栋7117房'},
            {name:'牛',sex:'男',age:81,orgCode:'番禺区小谷围街道星汇社区键盘花园WN栋7117房'},
        ],
        loading:false,
        page:0,
        total:0,
        pageSize:20,
      }          
    },
    //方法集合
    methods: {
        tableSelete(val){
            console.log(val);  
        }
    },
    //生命周期 - 创建完成（可以访问当前this实例）
    created() {},
  }
</script>
<style lang='less' scoped>
  //编写css
  .distribution-div{
      width: 100%;
      .container{
          width: 100%;
          display:flex; 
          justify-content: space-between;
          .tree-left{
            height: 800px;
            width: 15%;
            border: 1px solid rgba(000, 000, 000, 0.1);
            overflow-y: scroll;
            position: relative;
            padding: 40px 0 10px 10px ;
            .tree-title{
                top: 0;
                left: 0;
                background: #f8f8f9;
                color: #5A516E;
                position: absolute;
                width: 100%;
                text-align: center;
                height: 40px;
                line-height: 40px;
            }
        } 
        .table-right{
            margin-left: 20px;
            height: 800px;
            width:85%;
            overflow-y: scroll;
            bottom: 1px solid red;
           /deep/ .ivu-table-wrapper-with-border{
                height: 100%;
            }
           /deep/ .ivu-table-tip{
                height: 100%;
                table{
                    height: 100%;
                }
           }
        }        
      }
  }
</style>
